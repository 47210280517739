.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99999;
    overflow-y: auto;
  }
  
  .modal-main {
    position: absolute;
    background: white;
    width: 50%;
    max-width: 800px;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 1.5rem 2rem;
    border-radius: .1rem;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  .modal-close {
    position: absolute;
    width: 35px;
    height: 35px;
    top: -10px;
    right: -10px;
    background-color: #fff;
    padding: .5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    color: #111;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-close-icon {
    width: 15px;
    height: 15px;
    fill: #333;
    color: #333;
  }


  .project-image-wrapper {
    width: 100%;
    height: 300px;
    max-height: 300px;
    display: block;
    margin-bottom: 1rem;
  }

  .project-modal-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .project-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .project-link {
    display: block;
    color: #008bff;
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  .project-description-title {
    font-size: 1.3rem;
    margin: 1rem 0;
    margin-bottom: .5rem;
    color: #111;
    border-bottom: 1px solid #ccc;
    padding-bottom: .5rem;
  }

  .project-description {
    margin-top: 10px;
    font-size: 1.1rem;
    font-weight: 500;
    color: #111;
  }

  .modal-project-categories {
    width: 100% !important;
    height: auto;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    gap: .5rem !important;

  }

  @media screen and (max-width: 992px) {
    .modal-main {
        width: 90%;
        margin-top: 2rem;
    }

    .project-image-wrapper {
        width: 100%;
        height: 500px;
    }

    .project-modal-image {
        min-height: 100%;
        width: 100%;
        object-fit: cover;
    }
  }