.onscreen {
    top: 0;
    height: 70px !important;
    background-color: #191919;
    z-index: 9999;
}

.header {
    position: fixed;
    left: 0;
    right: 0;
    height: 100px;
    transition: height .4s ease;
    .container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .brand {
            font-size: 1.2rem;
        }

        .menu {
            background-color: #222;
            position: fixed;
            height: 100%;
            top: -100%;
            bottom: 0;
            left: 0;
            right: 0;
            transition: top .5s ease;
            z-index: 9999;
      
            .menu__content {
              padding: 30px;
              display: flex;
              flex-direction: column;
      
              .hideMenu {
                outline: none;
                border: none;
                background-color: transparent;
                cursor: pointer;
                margin-left: auto;
                margin-bottom: 2rem;
      
                svg {
                  color: #fff;
                  width: 25px;
                  height: 25px;
                  fill: #fff;
                }
              }
      
              .menu-link {
                color: #fff;
                text-decoration: none;
                font-size: 1.1rem;
                font-weight: 500;
                padding-bottom: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                position: relative;
      
                .link-underline {
                    display: block;
                    height: 3px;
                    width: 0;
                    background-color: transparent;
                    border-radius: 10px;
                    transition: all .2s ease;
                    position: absolute;
                    top: calc(100% - 3px);
                  }
      
                &.active {
                  color: #008bff;
                  
                  .link-underline {
                    width: 100%;
                    background-color: #008bff;
                  }
                }
              }
            }
          }
      
          .social {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-left: auto;
            margin-right: 20px;
           
            svg {
              width: 15px;
              height: 15px;

              &:hover {
                animation: rubberBand .3s ease;
              }
            }
          }

        .buttonShowMenu {
            background-color: transparent;
            outline: none;
            border: none;
            cursor: pointer;
            
            svg {
                width: 30px;
                height: 30px;
                fill: #fff;
                color: #fff;
            }
        }
    }
}


@media screen and (min-width: 1024px) {
    .header {
        height: 80px;
        .container {
            align-items: center;

            .brand {
                font-size: 20px;
            }

            .menu {
                height: 100%;
                background-color: transparent;
                position: static;
                margin-right: auto;
                margin-left: 30px;

                .menu__content {
                height: 100%;
                flex-direction: row;
                align-items: center;
                padding: 0;
                margin-left: 30px;

                .hideMenu {
                    display: none;
                }

                .menu-link {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    font-size: 16px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    margin-right: 30px;

                    &:hover {
                    animation: rubberBand .6s;
                    animation-fill-mode: forwards;
                    }
                }
                }
            }

            .social {
                margin-right: 0;
                gap: 2rem;

                svg:hover {
                    animation: rubberBand .6s;
                    animation-fill-mode: forwards;
                }
            }

            .buttonShowMenu {
                display: none;
            }
        }
    }
}