.footer {
    background-color: #111;
    padding: 35px 0;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .brand {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }

        .social {
            display: flex;
            gap: 2rem;
            margin-bottom: 1rem;
            svg {
                border: 2px solid #999;
                padding: .7rem;
                border-radius: 50%;
                transition: all .5s ease;

                &:hover {
                    background-color: #008bff;
                    border-color: #008bff;
                    transform: translateY(-2px);
                    color: #fff;
                }
            }
        }

        .rights {
            color: #999;
            font-size: 1rem;
        }
    }
}

@media screen and (min-width: 756px) {
    .footer {
        .container {
            flex-direction: row;
            justify-content: space-between;

            .brand, .social {
                margin-bottom: 0;
            }
        }
    }
}