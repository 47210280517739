.services {
    background-color: #101010;
    .container {
        padding: 50px 0;

        .text-zone {
            text-align: center;
            margin-bottom: 4rem;

            .title {
                color: #fff;
                font-size: 2.5rem;
            }
        }

        .services-list {
            .service {
                background-color: #222;
                padding: 30px 20px;
                border-radius: 15px;
                text-align: center;
                margin-bottom: 2rem;
                transition: all .4s ease;

                &:hover {
                    background: linear-gradient(to right, #0cf, #008bff);
                    transform: translateY(-10px);

                    h3, p {
                        color: #fff;
                    }
                }

                svg {
                    fill: #008bff;
                    color: #008bff;
                    width: 40px;
                    height: 40px;
                    margin-bottom: .5rem;
                }

                h3 {
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: #f1f1f1;
                    margin-bottom: .5rem;
                }

                p {
                    color: #aaa;
                    font-size: 1.1rem;
                    margin-bottom: .5rem;
                }
            }
        }
    }
}

@media screen and (min-width: 540px) {
    .services {
        .container {
            .text-zone {
                .title {
                    font-size: 2.8rem;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .services {
        .container {
            .services-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                .service {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 860px) {
    .services {
        .container {
            padding: 100px 0;

            .text-zone {
                .title {
                    font-size: 3.7rem;
                }
            }

            .services-list {
                .service {
                    padding: 50px 40px;
                    svg {
                        width: 50px;
                        height: 50px;
                        margin-bottom: 1.3rem;
                    }
                    h3 {
                        margin-bottom: 1.3rem;
                    }

                    p {
                        margin-bottom: 1.3rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .services {
        .container {
            padding: 150px 0;
            .services-list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;

                .service {
                    padding: 10px;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .services {
        .container {
            .services-list {
                width: 80%;
                margin: 0 auto;
                gap: 2rem;

                .service {
                    padding: 50px 40px;
                }
            }
        }
    }
}