.about {
    background-color: #222;
    .container {
        padding: 50px 0;

        .text-zone {
            .title {
                color: #fff;
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }
    
            .about-text {
                font-size: 1.2rem;
                color: #999;
            }
        }

        .skills {
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;

            .flex {
                display: flex;
                justify-content: space-between;
                svg {
                    color:#008bff;
                    border: 2px solid #008bff;
                    border-radius: 10px;
                    padding: 1rem;
                    width: 30px;
                    height: 30px;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@media screen and (min-width: 540px) {
    .about {
        .container {
            .text-zone {
                .title {
                    font-size: 2.8rem;
                }
            }

            .skills {
                .flex {
                    justify-content: flex-start;
                    svg {
                        margin-right: 1rem;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 860px) {
    .about {
        .container {
            padding: 150px 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .text-zone {
                .title {
                    font-size: 3.7rem;
                }
    
                .about-text {
                    font-size: 1.3rem;
                }
            }

            .skills {
                margin-left: auto;
                gap: 1rem;

                .flex {
                    svg {
                        margin-left: 1.5rem;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}