.hire {
    background-color: #222;
    .container {
        padding: 50px 0;

        .text-zone {
            margin-bottom: 2rem;
            .title {
                color: #fff;
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }

            .text {
                color: #999;
                font-size: 1.2rem;
                margin-bottom: .5rem;
            }

            .email {
                color: #fff;
                font-size: 1.2rem;
                font-weight: 600;
            }
        }

        .hire-zone {
            .hire-form {
                .form-group {
                    margin-bottom: 1rem;
                    input, textarea {
                        width: 100%;
                        border: 2px solid #333;
                        border-radius: 5px;
                        background-color: transparent;
                        padding: 1rem;
                        display: block;
                        font-size: 1rem;
                        outline: none;
                        transition: all .3s ease;
                        color: #fff;

                        &:focus {
                            background-color: transparent;
                            border-color: #008bff;
                            color: #fff;
                        }
                    }

                    &.form-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        
                        button {
                            border: 2px solid #008bff;
                            background-color: transparent;
                            outline: none;
                            padding: .6rem 2rem;
                            border-radius: 5px;
                            color: #008bff;
                            font-size: 1rem;
                            font-weight: 500;
                            text-transform: uppercase;
                            transition: all .5s ease;
                            cursor: pointer;

                            &:hover {
                                background-color: #008bff;
                                transform: translateY(-2px);
                                color: #fff;
                            }
                        }

                    }

                    textarea {
                        min-height: 150px;
                    }

                    &.two-inputs {
                        display: flex;
                        gap: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 540px) {
    .hire {
        .container {
            .text-zone {
                .title {
                    font-size: 2.8rem;
                }
            }
        }
    }
}

@media screen and (min-width: 860px) {
    .hire {
        .container {
            .text-zone {
                .title {
                    font-size: 3.7rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .hire {
        .container {
            padding: 150px 0;
            .text-zone {
                .title {
                    font-size: 3.7rem;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .hire {
        .container {
            max-width: 50%;
        }
    }
}