* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

a {
  color: #fff;
  text-decoration: none;
}

.container {
  max-width: 80%;
  margin: 0 auto;
}

.custom-gradient {
  background: linear-gradient(to right, #0cf, #008bff);
}

@media screen and (min-width: 756px) {
  .container {
    max-width: 85%;
  }
}

#error-page {
  background-color: #111;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;

  div {
    h1 {
      font-size: 3rem;
    }
  }
}