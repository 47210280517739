.home {
    padding-top: 90px;

    .container {
        padding: 90px 0;
        
        .secondary-text {
            display: block;
            color: #999;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 5px;
            margin-bottom: .5rem;
        }

        .title {
            color: #fff;
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 1;
            margin-bottom: .9rem;

            &.experience {
                color:  #008bff;

                div {
                    z-index: -1 !important;
                    height: 40px;
                    display: flex;
                    align-items: center;
                }
            }

        }
    }
}

@media screen and (min-width: 640px) {
    .home {
        .container {
            .title {
                font-size: 3.5rem;

                &.experience {

    
                    div {
                        height: 80px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .home {
        padding-top: 90px;
    
        .container {
            .secondary-text {
                font-size: 1.1rem;
            }
            
            &.content {
                margin-top: 0;
            }
    
            .title {
                font-size: 4.7rem;
                line-height: 1.2;
    
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .home {
        height: 100vh;
        .container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}