.portfolio {
    background-color: #333;

    .container {
        padding: 50px 0;

        .text-zone {
            text-align: center;
            .title {
                color: #fff;
                font-size: 2.3rem;
                margin-bottom: 1rem;
            }

            p {
                font-size: 1.2rem;
                color: #999;
            }
        }

        .projects {
            margin-top: 5rem;

            .project {
                height: 300px;
                width: 100%;
                border-radius: 5px;
                position: relative;
                flex-wrap: wrap;
                transition: transform .5s ease;
                cursor: pointer;
                background-size: cover;
                margin-bottom: 2rem;
                margin-left: auto;
                margin-right: auto;

                .overlay-details {
                    opacity: 1;
                    z-index: 10;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .technology {
                        color :#eee;
                        font-weight: 500;
                    }

                    .title {
                        color: #fff;
                        font-size: 2rem;
                        font-weight: 600;
                        margin-top: 2rem;
                    }

                    .link-button {
                        font-size: 1rem;
                        border: 2px solid #fff;
                        color: #eee;
                        padding: .5rem 1rem;
                        border-radius: 5px;
                        text-transform: uppercase;
                        font-weight: 500;
                        margin-top: 2rem;
                        cursor: pointer;
                        transition: all .2s ease;

                        &:hover {
                            color: #555;
                            background-color: #fff;
                            transform: translateY(-5px);
                        }

                    }
                }

                .overlay {
                    background: #111;
                    opacity: .4;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    transition: all .5s ease-in-out;
                    z-index: 9;
                    border-radius: 5px;
                }

                &:hover {
                    transform: translateY(-10px);

                    .overlay {
                        background-color: #008bff;
                        opacity: 1;
                    }
                }
            }
        }

        .more-projects {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;

            .link {
                border: 2px solid #008bff;
                background-color: transparent;
                outline: none;
                padding: .6rem 2rem;
                border-radius: 5px;
                color: #008bff;
                font-size: 1rem;
                font-weight: 500;
                text-transform: uppercase;
                transition: all .5s ease;
                cursor: pointer;

                &:hover {
                    background-color: #008bff;
                    transform: translateY(-2px);
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (min-width: 540px) {
    .portfolio {
        .container {    
            .text-zone {
                .title {
                    font-size: 2.8rem;
                    margin-bottom: 1rem;
                }
    
                p {
                    font-size: 1.4rem;
                }
            }

            .projects {
                justify-content: center;
                flex-direction: row;
            }
        }
    }
}

@media screen and (min-width: 767px) {
    .portfolio {
        .container {
            .projects {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                .project {
                    width: 100%;
                    height: 300px;
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
      
            .more-projects {
                margin-top: 4rem !important;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .portfolio {
        .container {
            padding: 150px 0;
    
            .text-zone {
                margin-top: 2rem;
                .title {
                    font-size: 3.7rem;
                    margin-bottom: 1rem;
                }
    
                p {
                    font-size: 1.4rem;
                }
            }

            .projects {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 2rem;

                .project {
                    width: 100%;
                    height: 300px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .portfolio {
        .container {
            .projects {
                width: 70%;
                margin: 0 auto;
                margin-top: 4rem;

                .project {
                    width: 100%;
                }
            }
        }
    }
}